import axios from 'axios';
import { CONVERT_URL } from '../environment/environment';

export const ConversionService = {
    async convert(from: string, to: string, amount: number): Promise<number> {
        let result = 0;
        await axios
            .get(`${CONVERT_URL}${to}/market_chart?vs_currency=${from}&days=0`)
            .then(res => {
                const conversionResult = res.data.prices[0][1];
                result = amount * conversionResult;
            })
            .catch(() => (result = 0));
        return result;
    },
    formatPrice(price: number): string {
        return new Intl.NumberFormat('de-DE', {
            maximumFractionDigits: 2,
        }).format(price);
    },
};
