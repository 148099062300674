import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Avatar, Box } from '@mui/material';
import { Merchant } from '../../model/Merchant';
import { MerchantService } from '../../services/merchant.service';
import { UserContext } from '../../context';
import logo from '../../assets/logo_utente.png';

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
}));

export default function AccountPopover() {
    const [merch, setMerch] = useState<Merchant>();
    const { user } = useContext(UserContext);

    useEffect(() => {
        const fetchMerchant = async () => {
            if (user) {
                try {
                    const result = await MerchantService.getMerchant(user);
                    console.log(result);
                    setMerch(result);
                } catch (e) {
                    console.log(e);
                }
            }
        };
        fetchMerchant();
    }, [user]);

    return (
        <Box sx={{ my: 2.5, mx: 2.5 }}>
            <AccountStyle>
                <Avatar src={logo} alt={merch?.shopName} />
                <Box sx={{ ml: 2 }}>
                    <Typography
                        variant="subtitle2"
                        sx={{ color: 'text.primary' }}>
                        {merch?.shopName}
                    </Typography>
                </Box>
            </AccountStyle>
        </Box>
    );
}
