import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import UserProvider from './context/UserProvider';
import CurrencyProvider from './context/CurrencyProvider';
import BalanceProvider from './context/BalanceProvider';
import WalletProvider from './context/WalletProvider';
import IncomeProvider from './context/IncomeProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <UserProvider>
                <CurrencyProvider>
                    <BalanceProvider>
                        <WalletProvider>
                            <IncomeProvider>
                                <App />
                            </IncomeProvider>
                        </WalletProvider>
                    </BalanceProvider>
                </CurrencyProvider>
            </UserProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
