import { Link as RouterLink } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { GenericModalProps } from '../model/ModalInterface';

export default function GenericModal(props: GenericModalProps) {
    const {
        path,
        handleClose,
        open,
        title,
        functionBtn,
        message,
        textBtn,
        color,
    } = props;
    const getPropsBtn = {
        sx: [
            {
                mr: 1,
                mb: 1,
                order: 1,
                backgroundColor: color.main,
                '&:hover': {
                    backgroundColor: color.dark,
                },
            },
            theme => ({
                color: theme.palette.common.white,
            }),
        ],
        onClick: functionBtn && !path ? functionBtn : () => {},
        to: path && !functionBtn ? path : undefined,
        component: path && !functionBtn ? RouterLink : undefined,
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
            sx={{}}>
            <DialogContent
                sx={{
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                <DialogTitle variant="h4" sx={{ display: 'flex' }}>
                    {title}
                </DialogTitle>
                {message && (
                    <Typography variant="body1" sx={{ mx: 3, my: 2 }}>
                        {message}
                    </Typography>
                )}
                <DialogActions sx={{ display: 'flex' }}>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => {
                            handleClose();
                        }}
                        sx={[
                            { mr: 0, mb: 1, order: 2, boxShadow: 'none' },
                            theme => ({
                                backgroundColor: theme.palette.action.void,
                                color: theme.palette.common.white,
                                '&:hover': {
                                    backgroundColor:
                                        theme.palette.action.active,
                                },
                            }),
                        ]}>
                        Close
                    </Button>
                    {textBtn && <Button {...getPropsBtn}>{textBtn}</Button>}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
