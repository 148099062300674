import axios from 'axios';
import { BASE_URL } from '../environment/environment';
import { supportedChains } from '../mock/SupportedChain';
import { Chain } from '../model/Chain';
import { Login } from '../model/Login';
import { Merchant } from '../model/Merchant';
import { ConversionService } from './convert.service';

export const MerchantService = {
    async login(payload: Login): Promise<Merchant> {
        const response = await (
            await axios.post(`${BASE_URL}/auth/login`, payload)
        ).data.payload;
        const user: Merchant = response.user;
        const jwt: string = response.jwt;
        localStorage.setItem('jwt', jwt);
        return user;
    },
    async signUp(payload: Merchant): Promise<Merchant> {
        const response = await (
            await axios.post(`${BASE_URL}/auth/signup`, payload)
        ).data.payload;
        const user: Merchant = response.user;
        return user;
    },
    async logout() {
        const token = localStorage.getItem('jwt');
        console.log(token);

        await axios.post(
            `${BASE_URL}/auth/logout`,
            {},
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );

        localStorage.removeItem('jwt');
    },
    getMerchant: async function (id: string): Promise<Merchant> {
        const token = localStorage.getItem('jwt');
        return await (
            await axios.get(`${BASE_URL}private/merchant/${id}/get`, {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            })
        ).data.payload;
    },
    getCurrency: async function (
        id: string
    ): Promise<Map<string, number | undefined>> {
        let ret = new Map<string, number>();
        const appWallet = (await this.getMerchant(id)).wallet;
        await Promise.all(
            appWallet!.map(async i => {
                const coin: Chain | undefined = supportedChains.find(
                    elem => elem.currency === i.currency
                );
                coin &&
                    (await ConversionService.convert(
                        'EUR',
                        coin.coingeckoId,
                        1
                    ).then(result => {
                        ret.set(i.currency, result);
                    }));
            })
        );
        return ret;
    },
};
