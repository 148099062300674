import { ReactNode, ReactPortal, useState } from 'react';
import { WalletContext } from '.';
import { WalletItem } from '../model/WalletItem';

type WalletProviderProps = {
    children?: ReactNode | ReactPortal;
};

const WalletProvider = (props: WalletProviderProps) => {
    const [wallet, setWallet] = useState<WalletItem[]>([]);

    return (
        <WalletContext.Provider value={{ wallet, setWallet }}>
            {props?.children}
        </WalletContext.Provider>
    );
};

export default WalletProvider;
