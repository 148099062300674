import {
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context";
import NotImplementDialog from "../../../components/NotImplementDialog";
import { WalletItem } from "../../../model/WalletItem";
import { MerchantService } from "../../../services/merchant.service";

export default function CryptoWithdrawal() {
  const {user} = useContext(UserContext);
  const [wallet, setWallet] = useState<WalletItem[]>();
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [load, setLoad] = useState<Boolean>(false);

  useEffect(() => {
    const fetchMerchant = async () => {
      setLoad(false);
      user &&
        (await MerchantService.getMerchant(user).then((result) => {
          setWallet(result.wallet);
          setLoad(true);
        }));
    };  
    fetchMerchant();
  }, [user]);

  return (
    <FormControl sx={{ m: 5, minWidth: "40%" }}>
      <Stack spacing={3}>
        <Typography variant="h3">Crypto Withdrawal</Typography>
        <TextField select defaultValue="" label="chain">
          {
            wallet && wallet.map((item) => (
              <MenuItem value={item.currency}>{item.name}</MenuItem>
            ))
          }
        </TextField>
        <TextField variant="outlined" label="address" />
        <Stack direction="row" spacing={2}>
          <TextField variant="outlined" label="Amount" />
          <Typography
            variant="body1"
            sx={{
              py: {
                xs: 0,
                sm: 2,
              },
            }}
          >
            Available Amount: 75,4 CRP
          </Typography>
        </Stack>
        <NotImplementDialog text="Send coins to my wallet" />
        {/* <Button variant="contained" size="large">Send coins to my wallet</Button> */}
      </Stack>
    </FormControl>
  );
}
