import { ReactNode, ReactPortal, useState } from 'react';
import { BalanceContext } from '.';

type BalanceProviderProps = {
    children?: ReactNode | ReactPortal;
};

const BalanceProvider = (props: BalanceProviderProps) => {
    const [accBalance, setAccBalance] = useState<string>('0');

    return (
        <BalanceContext.Provider value={{ accBalance, setAccBalance }}>
            {props?.children}
        </BalanceContext.Provider>
    );
};

export default BalanceProvider;
