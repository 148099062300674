import { useMemo } from 'react';
// material
import { CssBaseline, Palette, PaletteOptions } from '@mui/material';
import {
    ThemeProvider,
    createTheme,
    StyledEngineProvider,
} from '@mui/material/styles';
//
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Shadows } from '@mui/material/styles/shadows';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';
import ComponentsOverrides from './overrides';

// ----------------------------------------------------------------------

declare module '@mui/material/styles' {
    interface Theme {
        palette: {
            background: {
                paper: string;
                default: string;
                neutral: string;
            };
            success: any;
            text: any;
            grey: any;
            mode: any;
            primary: any;
            chart: any;
            divider: any;
            common: any;
            action: any;
            info: any;
            error: any;
            warning: any;
        };
        shape: {
            borderRadius: number;
        };
        typography: {
            body2: {
                lineHeight: number;
                fontSize: number;
            };
            fontWeightBold: number;
            subtitle2: any;
            h3: any;
            fontFamily: any;
        };
        shadows: Shadows;
        customShadows: any;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        palette?: PaletteOptions;
        shape?: {};
        typography?:
            | TypographyOptions
            | ((palette: Palette) => TypographyOptions);
        shadows?: Shadows;
        customShadows?: {};
    }
}

type ThemeProps = {
    children: any;
};

export default function ThemeConfig(props: ThemeProps) {
    const { children } = props;
    const themeOptions: any = useMemo(
        () => ({
            palette,
            shape: { borderRadius: 8 },
            typography,
            shadows,
            customShadows,
        }),
        []
    );

    const theme = createTheme(themeOptions);
    theme.components = ComponentsOverrides(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
