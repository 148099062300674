import { CartItem } from "./CartItem";
import { Merchant } from "./Merchant";

export enum PaymentAction {
  PAYMENT = "payment",
  DEPOSIT = "deposit",
  WITHDRAW = "withdraw"
}

export type Payment = {
  _id: string;
  cartId?: string;
  amount?: number;
  finalAmount: number;
  currency: string;
  merchantAddress: string;
  customerAddress: string;
  closed: boolean;
  action: PaymentAction;
  merchant?: Merchant;
  date: Date;
  cart: CartItem[];
};
