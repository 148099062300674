import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import Loader from './components/Loader';
import ScrollToTop from './components/ScrollToTop';
import {
    BalanceContext,
    CurrencyContext,
    UserContext,
    WalletContext,
} from './context';
import Router from './routes';
import { ConversionService } from './services/convert.service';
import { MerchantService } from './services/merchant.service';
import ThemeConfig from './theme';

export default function App() {
    const { user, setUser } = useContext(UserContext);
    const { currency, setCurrency } = useContext(CurrencyContext);
    const { setAccBalance } = useContext(BalanceContext);
    const { wallet, setWallet } = useContext(WalletContext);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        console.log(userId);
        if (userId !== null) setUser(userId);
    }, [setUser]);

    useEffect(() => {
        const calculateBalance = () => {
            let sum = 0;
            let tmp: number | undefined;
            wallet.forEach(item => {
                currency && (tmp = currency?.get(item.currency));
                tmp && (sum += tmp * item.amount);
            });
            setAccBalance(ConversionService.formatPrice(sum));
        };
        currency && calculateBalance();
    }, [currency, setAccBalance, wallet]);

    useEffect(() => {
        const fetchCurrency = async () => {
            if (user) {
                try {
                    const result = await MerchantService.getCurrency(user);
                    setCurrency(result);
                } catch (e) {
                    console.log(e);
                }
            }
        };
        user && fetchCurrency();
        setInterval(async () => user && fetchCurrency(), 900000);
    }, [user, setCurrency]);

    useEffect(() => {
        const fetchMerchant = async () => {
            try {
                if (user) {
                    const result = await MerchantService.getMerchant(user);
                    setUser(result._id!);
                    setWallet(result.wallet!);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchMerchant();
    }, [user, setUser, setWallet]);

    return (
        <ThemeConfig>
            <ScrollToTop />
            {load ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}>
                    <Loader />
                </Box>
            ) : (
                <Router />
            )}
        </ThemeConfig>
    );
}
