import { Box } from '@mui/material';
import { useState } from 'react';

import Page from '../components/Page';
import FormAuthentication from '../components/FormAuthentication';

export default function AuthenticationPage() {
    const [loginState, setLoginState] = useState(true);
    const [recoveryState, setRecoveryState] = useState(false);
    return (
        <Page margin={0} title="Authentication_page">
            <Box
                display="flex"
                alignItems="center"
                justifyContent={loginState ? 'flex-start' : 'center'}
                sx={{
                    height: '100vh',
                    transition: 'all ease .8s',
                }}>
                {loginState ? (
                    <FormAuthentication
                        recoveryPass={() => {
                            setRecoveryState(!recoveryState);
                        }}
                        recovery={recoveryState}
                        state={loginState}
                        registerState={() => {
                            setLoginState(false);
                        }}
                        size={400}
                    />
                ) : (
                    <FormAuthentication
                        recoveryPass={() => {
                            setRecoveryState(!recoveryState);
                        }}
                        recovery={recoveryState}
                        state={loginState}
                        registerState={() => {
                            setLoginState(true);
                        }}
                        size="lg"
                    />
                )}
            </Box>
        </Page>
    );
}
