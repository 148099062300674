import { ReactNode, ReactPortal, useState } from "react"
import { UserContext } from ".";

type UserProviderProps = {
    children?: ReactNode | ReactPortal;
};

const UserProvider = (props: UserProviderProps) => {
    const [user, setUser] = useState<string>("");

    return (<UserContext.Provider value={{user, setUser}}>
        {props?.children}
    </UserContext.Provider>);
}

export default UserProvider;