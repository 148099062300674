import { styled } from '@mui/material/styles';
import { Box, Button, Drawer } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import NavSection from '../../components/NavSection';
import sidebarConfig from './SidebarConfig';
import Logo from '../../components/Logo';
import { MerchantService } from '../../services/merchant.service';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

type DashboardSidebarProps = {
    isOpenSidebar: boolean;
    onCloseSidebar: () => void;
};

export default function DashboardSidebar(props: DashboardSidebarProps) {
    const { isOpenSidebar, onCloseSidebar } = props;
    const isDesktop = useResponsive('up', 'sm');

    const logout = async () => {
        await MerchantService.logout();
        window.location.reload();
    };

    const renderContent = (
        <>
            <Box sx={{ my: 5, textAlign: 'center' }}>
                <Logo />
            </Box>

            <NavSection
                navConfig={sidebarConfig}
                isOpenSidebar={isOpenSidebar}
                onCloseSidebar={onCloseSidebar}
            />

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ m: 2 }}>
                <Button variant="contained" fullWidth onClick={() => logout()}>
                    Logout
                </Button>
            </Box>
        </>
    );

    return (
        <RootStyle>
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: '100%' },
                    }}>
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}>
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
