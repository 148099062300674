import {
    Box,
    Card,
    Button,
    Divider,
    CardHeader,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TableBody,
    Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useContext, useEffect, useState } from 'react';
import { Payment } from '../../../model/Payment';
import { PaymentService } from '../../../services/payment.service';
import { ConversionService } from '../../../services/convert.service';
import { CurrencyContext, UserContext } from '../../../context';
import Loader from '../../../components/Loader';
import NotImplementDialogInner from '../../../components/NotImplementDialogInner';

type NewItemProps = {
    item: Payment;
};

function NewItem(props: NewItemProps) {
    const { item } = props;
    const { currency } = useContext(CurrencyContext);

    const getConv = () => {
        let tmp: number | undefined;
        currency && (tmp = currency?.get(item.currency));
        tmp && (tmp *= item.finalAmount);
        if (tmp) return ConversionService.formatPrice(tmp).toString() + ' €';
        else return 'Conversion not available';
    };

    return (
        <TableRow>
            <TableCell sx={{ minWidth: '150px' }}>
                {' '}
                {new Date(item.date).toDateString()}
            </TableCell>
            <TableCell>{item.action}</TableCell>
            <TableCell>{item.currency}</TableCell>
            <TableCell>
                {item.finalAmount?.toString().substring(0, 8) ?? ''}
            </TableCell>
            <TableCell sx={{ minWidth: '100px' }}>{getConv()}</TableCell>
        </TableRow>
    );
}

export default function History() {
    const [payments, setPayments] = useState<Payment[]>();
    const [load, setLoad] = useState(true);
    const [open, setOpen] = useState(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const fetchPayments = async () => {
            if (user) {
                try {
                    const result = await PaymentService.getClosedPayments(user);
                    setPayments(result);
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoad(false);
                }
            }
        };
        fetchPayments();
    }, [user]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card>
            <CardHeader title="History" />
            {!load ? (
                <TableContainer>
                    <Table sx={{ width: '90%', mx: 'auto' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Coin</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payments ? (
                                payments.map(item => (
                                    <NewItem key={item._id} item={item} />
                                ))
                            ) : (
                                <Typography variant="body1" sx={{ my: 3 }}>
                                    There's no payment to be found.
                                </Typography>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Loader margin={40} />
            )}
            <Divider />
            <Box sx={{ p: 2, textAlign: 'right' }}>
                <Button
                    size="small"
                    color="inherit"
                    endIcon={<KeyboardArrowUpIcon />}
                    onClick={handleOpen}>
                    View all
                </Button>
                <NotImplementDialogInner
                    open={open}
                    handleClose={handleClose}
                />
            </Box>
        </Card>
    );
}
