import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
} from '@mui/material';

type NotImplProps = {
    open: boolean;
    handleClose: () => void;
};

export default function NotImplementDialogInner(props: NotImplProps) {
    const { open, handleClose } = props;
    return (
        <Dialog open={open} onClose={handleClose} sx={{ minWidth: '50%' }}>
            <DialogTitle>Not Implemented</DialogTitle>
            <Typography variant="body1" sx={{ mx: 10, mt: 2 }}>
                This feature is not yet implemented. Sorry for the
                inconvenience.
            </Typography>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    sx={[
                        { mr: 1, mb: 1 },
                        theme => ({
                            backgroundColor: theme.palette.error.lighter,
                            color: theme.palette.error.main,
                            '&:hover': {
                                backgroundColor: theme.palette.error.light,
                                color: theme.palette.error.contrastText,
                            },
                        }),
                    ]}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
