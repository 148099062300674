import { createContext } from 'react';
import { WalletItem } from '../model/WalletItem';

type UserContextType = {
    user?: string;
    setUser: (user: string) => void;
};

export const UserContext = createContext<UserContextType>(
    {} as UserContextType
);

type CurrencyContextType = {
    currency?: Map<string, number | undefined>;
    setCurrency: (currency: Map<string, number | undefined>) => void;
};

export const CurrencyContext = createContext<CurrencyContextType>(
    {} as CurrencyContextType
);

type BalanceContextType = {
    accBalance?: string;
    setAccBalance: (accBalance: string) => void;
};

export const BalanceContext = createContext<BalanceContextType>(
    {} as BalanceContextType
);

type IncomeContextType = {
    monthlyIncome?: string;
    setMonthlyIncome: (accBalance: string) => void;
};

export const IncomeContext = createContext<IncomeContextType>(
    {} as IncomeContextType
);

type WalletContextType = {
    wallet: WalletItem[];
    setWallet: (x: WalletItem[]) => void;
};

export const WalletContext = createContext<WalletContextType>(
    {} as WalletContextType
);
