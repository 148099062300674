import axios from 'axios';
import { BASE_URL } from '../environment/environment';
import { Payment } from '../model/Payment';
import { QrPayment } from '../model/QrPayment';

export const PaymentService = {
    getAllPayments: async function (): Promise<Payment[]> {
        return await (
            await axios.get(`${BASE_URL}/payment/all`)
        ).data.payload;
    },
    getPayment: async function (id: string): Promise<Payment[]> {
        return await (
            await axios.get(`${BASE_URL}/payment/${id}`)
        ).data.payload;
    },
    deletePayment: async function (id: string): Promise<Payment[]> {
        return await (
            await axios.put(`${BASE_URL}/payment/${id}`)
        ).data.payload;
    },
    getClosedPayments: async function (id: string): Promise<Payment[]> {
        return await (
            await axios.get(`${BASE_URL}/payment/merchant/${id}`)
        ).data.payload;
    },
    createPayment: async function (payload: QrPayment): Promise<QrPayment> {
        return await (
            await axios.post(`${BASE_URL}/payment/create`, payload)
        ).data.payload;
    },
};
