import { Box, Grid, TextField, Typography, Button } from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';

export default function RecoveryPass() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [formReady, setFormReady] = useState(true);

    useEffect(() => {
        if (password === confirmPassword) {
            setErrorPassword(false);
            setFormReady(false);
        } else {
            setErrorPassword(true);
            setFormReady(true);
        }
    });

    return (
        <Page margin={0} title="Authentication_page">
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                    height: '100vh',
                    transition: 'all ease .8s',
                }}>
                <Grid
                    maxWidth={400}
                    flexDirection="column"
                    container
                    minHeight={'50%'}
                    sx={[
                        {
                            transition: 'all ease .8s',
                            borderRadius: '.8rem',
                            mx: 6,
                            p: 6,
                        },
                        theme => ({
                            boxShadow: theme.customShadows.z20,
                            backgroundColor: theme.palette.common.white,
                        }),
                    ]}>
                    <Grid
                        item
                        flexDirection="row"
                        container
                        alignItems="center"
                        sx={{ mb: 3 }}>
                        <Typography variant="h2">Set new Password</Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                        my="auto">
                        <Grid item xs={12} sm={12} order={1}>
                            <TextField
                                onChange={e => {
                                    setPassword(e.target.value);
                                }}
                                value={password}
                                name="password"
                                fullWidth
                                required
                                type="password"
                                variant="outlined"
                                label="Password"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} order={1}>
                            <TextField
                                onChange={e => {
                                    setConfirmPassword(e.target.value);
                                }}
                                value={confirmPassword}
                                name="confirmPassword"
                                fullWidth
                                required
                                type="password"
                                variant="outlined"
                                label="Confirm password"
                                error={errorPassword}
                            />
                        </Grid>
                        <Grid item order={6} xs={12} sm={12} sx={{ mt: 2 }}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                disabled={formReady}
                                onClick={() => {
                                    //call api
                                }}>
                                Set new Password
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}
