
import { Button, Container, Grid } from "@mui/material";
import Page from "../components/Page";
import History from "../sections/dashboard/withdrawDeposit/History";
import { Link as RouterLink } from 'react-router-dom';
import NotImplementDialog from "../components/NotImplementDialog";


// ----------------------------------------------------------------------

export default function WithdrawDeposit() {
  return (
    <Page title="Withdraw/Deposit">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Button component={RouterLink} to="/dashboard/withdraw" variant="contained" size="large"  sx={{width:"100%"}}>Withdraw</Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <NotImplementDialog text="Deposit" />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <History />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
