import 'dotenv/config';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import 'react-toastify/dist/ReactToastify.css';
import ABI from '../environment/contract_abi.json';
import { INFURA_WSS_NODE_URL } from '../environment/environment';
import { CONTRACT_ADDRESS } from '../environment/environment';
import palette from '../theme/palette';
export class EventService {
    private readonly instance: Web3;
    private readonly contract: Contract;

    constructor() {
        this.instance = new Web3(
            new Web3.providers.WebsocketProvider(INFURA_WSS_NODE_URL)
        );
        console.log('[Web3] Creating contract instance');
        this.contract = new this.instance.eth.Contract(
            ABI as AbiItem[],
            CONTRACT_ADDRESS
        );
    }

    public async subscribeToPayment(
        _paymentId: string,
        handleOpen: (obj) => void
    ) {
        console.log('subscribe to event: ', _paymentId);
        this.contract.events
            .Payment({
                filter: { paymentId: _paymentId },
            })
            .on(
                'data',
                (event: {
                    returnValues: {
                        paymentId: string;
                        customerAddress: string;
                        finalAmount: string;
                        currency: string;
                        coinName: string;
                    };
                }) => {
                    // ###### MIGHT KEEP THESE FOR LATER USE ####
                    // console.log(`paymentId: - ${event.returnValues.paymentId}`);
                    // console.log(
                    // 	`customerAddress: - ${event.returnValues.customerAddress}`
                    // );
                    // console.log(
                    // 	`finalAmount: - ${event.returnValues.finalAmount}`
                    // );
                    // console.log(`currency: - ${event.returnValues.currency}`);
                    // console.log(`coinName: - ${event.returnValues.coinName}`);
                    if (event.returnValues.paymentId === _paymentId) {
                        const paymentSuccesful = {
                            title: 'Payment successful',
                            message:
                                'the customer has completed the transaction, go back to the history to see the payment',
                            path: '../withdraw_deposit',
                            textBtn: 'back to history',
                            functionBtn: undefined,
                            color: palette.primary,
                        };
                        handleOpen(paymentSuccesful);
                    }
                }
            );
    }
}
