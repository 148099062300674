import { Button, Grid, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { LoginSignFormProps } from '../model/LoginSignFormProps';
import { MerchantService } from '../services/merchant.service';
import { Login } from '../model/Login';
import { UserContext, WalletContext } from '../context';
import { Merchant } from '../model/Merchant';

export default function FormAuthentication(props: LoginSignFormProps) {
    const { state, registerState, size, recoveryPass, recovery } = props;
    const [errorPass, setErrorPass] = useState(false);
    const [shopName, setShopName] = useState('');
    const [formReady, setFormReady] = useState(true);
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [email, setEmail] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const { wallet, setWallet } = useContext(WalletContext);
    const { user, setUser } = useContext(UserContext);

    const registerApiCall = async () => {
        const objRegister: Merchant = {
            shopName: shopName,
            email: email,
            password: password,
            wallet: [],
        };
        try {
            console.log(objRegister);
            const response = await MerchantService.signUp(objRegister);
            console.log('Register API response: ' + response);
            window.location.reload();
        } catch (e) {
            console.log(e);
        }
    };

    const recoveryApiCall = async () => {
        console.log('recovery');
    };

    const loginApiCall = async () => {
        const objLogin: Login = {
            email: email,
            password: password,
        };
        try {
            const response = await MerchantService.login(objLogin);
            localStorage.setItem('userId', response._id!);
            setUser(response._id!);
            setWallet(response.wallet!);
        } catch (e) {
            setErrorLogin(true);
            console.log(e);
        }
    };

    const changeState = state => {
        setPassword('');
        setEmail('');
        setShopName('');
        setConfirmPass('');
        if (state) {
            registerState();
        } else {
            recoveryPass();
        }
    };

    useEffect(() => {
        if (password === confirmPass) setErrorPass(false);
        else setErrorPass(true);
        if (email.includes('@') || email.length < 1) setErrorEmail(false);
        else setErrorEmail(true);

        if (!state) {
            if (
                !errorPass &&
                !errorEmail &&
                shopName &&
                email &&
                password &&
                confirmPass
            )
                setFormReady(false);
            else setFormReady(true);
        } else {
            if (recovery) {
                if (email) setFormReady(false);
                else setFormReady(true);
            } else {
                if (email && password) setFormReady(false);
                else setFormReady(true);
            }
        }
    }, [confirmPass, email, errorPass, password, recovery, shopName, state]);

    return (
        <Grid
            maxWidth={size}
            flexDirection="column"
            container
            minHeight={'50%'}
            sx={[
                {
                    transition: 'all ease .8s',
                    borderRadius: '.8rem',
                    mx: 6,
                    p: 6,
                },
                theme => ({
                    boxShadow: theme.customShadows.z20,
                    backgroundColor: theme.palette.common.white,
                }),
            ]}>
            <Grid
                item
                flexDirection="row"
                container
                alignItems="center"
                sx={{ mb: 3 }}>
                <Typography variant="h2">
                    {state
                        ? recovery
                            ? 'Recover your password'
                            : 'Welcome Back!'
                        : 'Join CryptoPay!'}
                </Typography>
                {recovery ? (
                    <Typography
                        sx={{
                            marginTop: 2,
                            color: 'gray',
                            fontSize: 14,
                        }}>
                        Please insert your e-mail, we'll send you the
                        instructions to change your password
                    </Typography>
                ) : (
                    <></>
                )}
            </Grid>
            <Grid
                item
                container
                rowSpacing={2}
                columnSpacing={2}
                my={!state ? 'auto' : 0}>
                <Grid item xs={12} sm={state ? 12 : 6} order={2}>
                    <TextField
                        onChange={e => {
                            setEmail(e.target.value);
                            setErrorLogin(false);
                            setErrorEmail(false);
                        }}
                        value={email}
                        name="email"
                        fullWidth
                        required
                        type="email"
                        variant="outlined"
                        label="email"
                        error={state ? errorLogin : errorEmail}
                    />
                </Grid>
                {recovery && state ? (
                    ''
                ) : (
                    <Grid item xs={12} sm={state ? 12 : 6} order={3}>
                        <TextField
                            onChange={e => {
                                setPassword(e.target.value);
                                setErrorLogin(false);
                            }}
                            value={password}
                            name="password"
                            fullWidth
                            required
                            type="password"
                            variant="outlined"
                            label="Password"
                            error={state ? errorLogin : false}
                        />
                    </Grid>
                )}

                {state ? (
                    ''
                ) : (
                    <>
                        <Grid item xs={12} sm={state ? 12 : 6} order={1}>
                            <TextField
                                onChange={e => setShopName(e.target.value)}
                                name="shopName"
                                value={shopName}
                                fullWidth
                                required
                                type="text"
                                variant="outlined"
                                label="Shop name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} order={4}>
                            <TextField
                                onChange={e => setConfirmPass(e.target.value)}
                                value={confirmPass}
                                name="confirmPass"
                                fullWidth
                                required
                                type="password"
                                variant="outlined"
                                label="Confirm password"
                                error={errorPass}
                            />
                        </Grid>
                    </>
                )}
                {!recovery ? (
                    <Grid
                        item
                        order={5}
                        xs={12}
                        container
                        justifyContent={state ? 'flex-end' : 'center'}
                        alignItems="center"
                        style={{ display: state ? 'flex' : 'none' }}>
                        <Typography
                            onClick={() => {
                                changeState(false);
                            }}
                            sx={[
                                {
                                    fontSize: 14,
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                },
                                theme => ({
                                    color: theme.palette.primary.main,
                                    '&:hover': {
                                        color: theme.palette.action.void,
                                    },
                                }),
                            ]}>
                            forgot password?
                        </Typography>
                    </Grid>
                ) : (
                    ''
                )}

                <Grid item order={6} xs={12} sm={state ? 12 : 6} sx={{ mt: 2 }}>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        disabled={formReady}
                        onClick={() => {
                            !state
                                ? registerApiCall()
                                : recovery
                                ? recoveryApiCall()
                                : loginApiCall();
                        }}>
                        {state
                            ? recovery
                                ? 'Send email'
                                : 'Login'
                            : 'Sign up'}
                    </Button>
                </Grid>
                <Grid
                    item
                    order={7}
                    sx={{ mt: 2 }}
                    sm={state ? 12 : 6}
                    container
                    justifyContent={'center'}
                    alignItems="center">
                    <Typography
                        onClick={() => {
                            recovery ? changeState(false) : changeState(true);
                        }}
                        sx={[
                            {
                                fontSize: 14,
                                textDecoration: 'underline',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            },
                            theme => ({
                                color: theme.palette.primary.main,
                                '&:hover': {
                                    color: theme.palette.action.void,
                                },
                            }),
                        ]}>
                        {state && !recovery ? 'Or sign up' : 'Go back to login'}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
