import { useState } from "react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotImplementDialogInner from "./NotImplementDialogInner";
//

// ----------------------------------------------------------------------

const ListItemStyle = styled((props: any) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

type NavItemProps = {
  item : sidebarType;
  active : (path: string) => boolean;
  isOpenSidebar : boolean;
  onCloseSidebar : () => void;
}

function NavItem(props: NavItemProps) {
  const { item, active, isOpenSidebar, onCloseSidebar } = props;
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const {title, path, act, icon, info, children} = item;
  const [open, setOpen] = useState(isActiveRoot);
  const [openDial, setOpenDial] = useState<boolean>(false);

  const handleOpenDial = () => {
    setOpenDial(true);
  }

  const handleCloseDial = () => {
    setOpenDial(false);
  }

  const handleClose = () => {
    if (isOpenSidebar) {
      onCloseSidebar();
  }
}

  const handleOpen = () => {
    setOpen((prev: boolean) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {open ? (
            <KeyboardArrowDownIcon sx={{ width: 16, height: 16, ml: 1 }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ width: 16, height: 16, ml: 1 }} />
          )}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item: any) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  if (act === true) {
  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      onClick={handleClose}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
    }

  return (
    <>
    <ListItemStyle
     onClick={handleOpenDial}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
    <NotImplementDialogInner open={openDial} handleClose={handleCloseDial} />
    </>
  );
}

type sidebarType = {
  title: string;
  path: string;
  act: boolean;
  icon?: any;
  info?: any;
  children?: any;
};

type NavSectionProps = {
  navConfig : sidebarType[];
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

export default function NavSection(props: NavSectionProps, ...other: any) {
  const { navConfig, isOpenSidebar, onCloseSidebar } = props;
  const { pathname } = useLocation();
  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item: sidebarType) => (
          <NavItem key={item.title} item={item} active={match} isOpenSidebar={isOpenSidebar} onCloseSidebar={onCloseSidebar} />
        ))}
      </List>
    </Box>
  );
}
