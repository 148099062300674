import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//pages
import DashboardApp from './pages/DashboardApp';
import WithdrawDeposit from './pages/WithdrawDeposit';
import Convert from './pages/Convert';
import Settings from './pages/Settings';
import NotFound from './pages/Page404';
import Withdrawal from './pages/Withdrawal';
import GetPaid from './pages/GetPaid';
import AuthenticationPage from './pages/AuthenticationPage';
import RecoveryPass from './pages/RecoveryPass';

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/dashboard',
            element: localStorage.getItem('jwt') ? (
                <DashboardLayout />
            ) : (
                <Navigate to="/login" />
            ),
            children: [
                { path: 'app', element: <DashboardApp /> },
                { path: 'withdraw_deposit', element: <WithdrawDeposit /> },
                { path: 'convert', element: <Convert /> },
                { path: 'settings', element: <Settings /> },
                { path: 'withdraw', element: <Withdrawal /> },
                { path: 'get_paid', element: <GetPaid /> },
            ],
        },
        {
            path: '/',
            element: !localStorage.getItem('jwt') ? (
                <LogoOnlyLayout />
            ) : (
                <Navigate to="/dashboard/app" />
            ),
            children: [
                { path: '/', element: <Navigate to="/dashboard/app" /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" /> },
                { path: 'login', element: <AuthenticationPage /> },
                { path: 'recovery_pass', element: <RecoveryPass /> },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}
