import {
  Button
} from "@mui/material";
import { ReactNode, ReactPortal, useState } from "react";
import NotImplementDialogInner from "./NotImplementDialogInner";

type NotImplProps = {
  text: string;
  children?: ReactNode | ReactPortal;
};

export default function NotImplementDialog(props: NotImplProps) {
  const text: string = props.text;
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        size="large"
        sx={{ width: "100%" }}
      >
        {text}
      </Button>
      <NotImplementDialogInner open={open} handleClose={handleClose} />
    </>
  );
}
