import { ReactNode, ReactPortal, useState } from 'react';
import { IncomeContext } from '.';

type IncomeProviderProps = {
    children?: ReactNode | ReactPortal;
};

const IncomeProvider = (props: IncomeProviderProps) => {
    const [monthlyIncome, setMonthlyIncome] = useState<string>('0');

    return (
        <IncomeContext.Provider value={{ monthlyIncome, setMonthlyIncome }}>
            {props?.children}
        </IncomeContext.Provider>
    );
};

export default IncomeProvider;
