// da sistemare

// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

type PageProps = {
    margin?: number;
    children: any;
    title: string;
};
export default function Page(props: PageProps) {
    const { children, title = '', margin = 5 } = props;
    return (
        <Box sx={{ mt: margin }}>
            <title>{title}</title>
            {children}
        </Box>
    );
}
